import CustomModal from "../../../components/ui/Modal";
import CustomTypography from "../../../components/ui/Typography";
import {Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Label from "../../../components/ui/InputLabel";
import CustomButton from "../../../components/ui/Button";
import CustomChip from "../../../components/ui/Chip";
import useTestListController from "../../../../view-controlllers/admin/useTestListController";
import CustomAlert from "../../../components/ui/Alert";
import SearchList from "../../../components/ui/SearchList";
import { Snackbar } from '@mui/material';

const SendTest = () => {

   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 8,
      borderRadius: 5
   };

   const {
      handleShareTest,
      onCloseCandidateModal,
      onSendSkillCandidate,
      onAddCandidate,
      onUserSearch,
      onRemoveCandidate,
      userFoundData,
      candidates,
      isLoadingAddCandidate,
      isFetchingFoundUser,
      openSnackbar,
      setOpenSnackbar,
   } = useTestListController();

   return (
      <CustomModal styles={style}>
         <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackbar(false)}
            message="The Test link has been copied to your clipboard!"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         />
         <CloseIcon
            sx={{
               position: 'absolute',
               top: 20,
               right: 25,
               cursor: 'pointer'
            }}
            onClick={() => onCloseCandidateModal()}
         />
         <Box>
            <CustomTypography
               title="Send Interview"
               fontWeight="900"
               fontSize={24}
               color="#000"
               style={{marginBottom: 15}}
            />
            <CustomAlert
               variant="filled"
               style={{mt: 1, mb: 1}}
            />
            <Box>
               <Box sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
               }}>
                  <CustomButton
                     onPress={() => handleShareTest()}
                     title='SHARE LINK'
                     variant="outlined"
                  />
               </Box>
               <Label
                  title="Add Candidates"
                  style={{
                     fontWeight: 'bold',
                     color: '#000',
                     fontSize: 12,
                     marginBottom: 5
                  }}
               />

               <SearchList
                  isFetching={isFetchingFoundUser}
                  data={userFoundData}
                  onChange={onUserSearch}
                  onClick={onAddCandidate}
               />

               <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  border: '1px solid grey',
                  flex: 1,
                  mt: 2,
                  p: 1
               }}>
                  {candidates?.length > 0 &&
                     <>
                        {candidates?.map((el) => (
                           <Box sx={{ml: 1, mt: 1, mb: 1}} key={el?._id}>
                              <CustomChip
                                 label={el?.name}
                                 element={el}
                                 onDelete={onRemoveCandidate}
                                 sx={{backgroundColor: '#9747FF52'}}
                              />
                           </Box>
                        ))}
                     </>
                  }
               </Box>
            </Box>

            <Box sx={{
               display: 'flex',
               justifyContent: 'center',
               mt: 5
            }}>
               <CustomButton
                  isLoading={isLoadingAddCandidate}
                  title="SEND INTERVIEW"
                  variant="contained"
                  size="small"
                  style={{borderRadius: 20,}}
                  onPress={() => onSendSkillCandidate()}
               />
            </Box>
         </Box>
      </CustomModal>
   )
}
export default SendTest;
