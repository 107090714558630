import {createTheme, makeStyles} from "@mui/material/styles";

export const theme = createTheme({
   palette: {
      background: {
         paper: '#fff',
      },
      primary: {
         main: '#6743D8',
      },
      secondary: {
         main: '#9747FF',
         dark: '#000',
      },
      text: {}
   },
})



