import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
   useFetchCandidatesQuery,
   useFetchCandidatesByNameOrEmailQuery,
   useFetchCandidateByIdQuery,
   usePdfTestMutation,
   useCreateCandidateMutation, useLazyFetchCandidateByIdQuery
} from "../../store/app/apiSlice/apiSlice";
import {
   setCandidateId,
   setCandidateTests,
   setCandidate,
   setTestResult,
   setPage,
   setSearchCriteria
} from "../../store/features/admin/candidates-slice";

const useCandidateViewModel = () => {
   const [limit, setLimit] = useState(10);
   const [pdfId, setPdfId] = useState(null);

   const dispatch = useDispatch();
   const {
      candidateId,
      candidateTests,
      candidate: dataCandidate,
      testResult,
      page,
      searchCriteria
   } = useSelector(state => state.CandidatesReducer);

   const {
      data: candidates,
      isFetching: isFetchingCandidates,
      isSuccess: isSuccessFetchCandidates,
      isError: isErrorFetchCandidates,
      refetch: refetchCandidates
   } = useFetchCandidatesQuery(
      {
      page: page,
      limit: limit,
      searchCriteria: searchCriteria
      },
   );

   const {
      data: candidatesByNameOrEmail,
      isFetching: isFetchingCandidatesEmailOrName,
      isSuccess: isSuccessFetchCandidatesNameOrEmail,
      isError: isErrorFetchCandidatesNameOrEmail
   } = useFetchCandidatesByNameOrEmailQuery(
      { query: ''},
      {skip: true},
   )

   const [triggerFetchCandidateById,{
      data: candidate,
      isFetching: isFetchingCandidate,
      isSuccess: isSuccessCandidate,
      isError: isErrorCandidate
   }] = useLazyFetchCandidateByIdQuery();

   const [pdfTest, {
      data: dataPdf,
      isSuccess: isSuccessPdf
   }] = usePdfTestMutation();

   const [createCandidate, {
      data: dataCandidateCreate,
      isSuccess: isSuccessCreateCandidate,
      isLoading: isLoadingCandidateCreate,
      isError: isErrorCandidateCreate,
      error: errorCandidateCreate
   }] = useCreateCandidateMutation();

   return {
      //Create candidate
      createCandidate,
      isSuccessCreateCandidate,
      dataCandidateCreate,
      isLoadingCandidateCreate,
      isErrorCandidateCreate,
      errorCandidateCreate,
      //Fetch pdf
      pdfTest,
      dataPdf,
      isSuccessPdf,
      //Fetch candidates
      candidates,
      isFetchingCandidates,
      isSuccessFetchCandidates,
      isErrorFetchCandidates,
      refetchCandidates,
      //Fetch candidates by name or email
      candidatesByNameOrEmail,
      isFetchingCandidatesEmailOrName,
      isSuccessFetchCandidatesNameOrEmail,
      isErrorFetchCandidatesNameOrEmail,
      //Fetch candidate by id
      triggerFetchCandidateById,
      candidate,
      isFetchingCandidate,
      isSuccessCandidate,
      isErrorCandidate,
      //local state
      page,
      limit,
      setLimit,
      searchCriteria,
      setPdfId,
      //Store state
      candidateTests,
      testResult,
      dataCandidate,
      //Fn
      setCandidateId: ((id) => dispatch(setCandidateId(id))),
      setCandidateTests: ((tests) => dispatch(setCandidateTests(tests))),
      setCandidate: ((candidate) => dispatch(setCandidate(candidate))),
      setTestResult: ((testResult) => dispatch(setTestResult(testResult))),
      setPage: ((page) => dispatch(setPage(page))),
      setSearchCriteria: ((searchCriteria) => dispatch(setSearchCriteria(searchCriteria)))
   }
}
export default useCandidateViewModel;
