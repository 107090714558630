import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   candidateId: null,
   candidateTests: [],
   candidate: {},
   testResult: {},
   page: 1,
   searchCriteria: ''
}

const candidatesSlice = createSlice({
   name: 'candidatesSlice',
   initialState,
   reducers: {
      setCandidateId: (state, action) => {
         state.candidateId = action.payload;
      },
      setCandidateTests: (state, action) => {
         state.candidateTests = action.payload;
      },
      setCandidate: (state, action) => {
         state.candidate = action.payload;
      },
      setTestResult: (state, action) => {
         state.testResult = action.payload;
      },
      setPage: (state, action) => {
         state.page = action.payload;
      },
      setSearchCriteria: (state, action) => {
         state.searchCriteria = action.payload;
      }
   }
})
export const {
   setCandidateId,
   setCandidateTests,
   setCandidate,
   setTestResult,
   setPage,
   setSearchCriteria
} = candidatesSlice.actions;
export default candidatesSlice.reducer;
