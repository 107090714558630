import CustomTypography from "../Typography";
import {Box} from "@mui/material";

const ErrorLabel = ({ error, message }) => {
   if (error) {
      return (
         <Box mt={1}>
            <CustomTypography
               title={message}
               fontSize={12}
               color={"red"}
            />
         </Box>
      )
   }
   return null
}
export default ErrorLabel;
