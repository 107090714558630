import React from 'react';
import Chip from '@mui/material/Chip';

const CustomChip = ({label, onDelete, onClick, element, sx, color, ...props}) => {

   return (
         <Chip
            label={label}
            onClick={onClick}
            onDelete={onDelete && (() => onDelete(element))}
            size="small"
            color={color}
            sx={sx}
            {...props}
         />
   );
}
export default CustomChip;
