import {
   setSkillId,
   setIdRecording,
   setIndexQuestion,
   setInputAudioSelected,
   setInputVideoSelected,
   setInterViewCredentials,
   setQuestions,
   setCompletedInterviewCredentials,
   setStartInterview,
   setCurrentStep,
   setResetStep,
   setResponseInteraction,
   setStatusInteractionFalse,
   setTranscripts,
   setProgressBar,
   setCurrentTime,
   setStatusTimer,
   setResetTranscripts,
   setCurrentQuestion,
   setCurrentSpeech,
   setActiveRecording,
   setSelfAssestments,
   resetInterviewState,
   resetTotalQuestions,
} from "../store/features/interview-slilce";

import {setContactInfo, setCredentials} from "../store/features/contactInfo-slice";

import {useDispatch, useSelector} from "react-redux";

import {
   useUploadVideoMutation,
   useCompleteInterviewMutation,
   useInteractionInterviewMutation,
   useUploadConversationMutation,
   useSignUpMutation,
   useUpdateProfesionalInfoMutation,
   useValidateCodeMutation,
   useLazyFetchSelfAssementQuery,
   useSkillQuery,
   useUploadSelfAssestmentMutation,
   useUploadInterviewFeedBackMutation,
} from "../store/app/apiSlice/apiSlice";

const useInterviewViewModel = () => {
   const dispatch = useDispatch();

   const {
      testId,
      idSkill,
      questions,
      idInterView,
      secret,
      indexQuestion,
      totalQuestions,
      idRecording,
      inputAudioSelected,
      inputVideoSelelcted,
      idInterviewCompleted,
      secretInterviewCompleted,
      startInterview,
      userName,
      currentStep,
      responseInteraction,
      transcripts,
      currentTime,
      progressBar,
      statusTimer,
      currentQuestion,
      currentSpeech,
      activeRecording,
      selfAssestments,
      enableRoute
   } = useSelector(state => state.InterviewReducer);

   const {contactInfo, credentials} = useSelector(state => state.ContactInfoReducer);

   const [
      uploadVideo, {
         data: dataVideo,
         isLoading: isLoadingVideo,
         isSuccess: isSuccessVideo,
         error: errorVideo,
      }] = useUploadVideoMutation();

   const [sendCompleteInterview, {
      isSuccess: isSuccessCompleteInterview,
      error: errorCompleteInterview
   }] = useCompleteInterviewMutation();

   const [sendInteraction, {
      data: interactionData,
      isLoading: isLoadingInteraction,
      isSuccess: isSuccessInteraction,
      isError: isErrorInteraction
   }] = useInteractionInterviewMutation();

   const [uploadConversation, {
      data: conversationData,
      isLoading: isLoadingUploadConversation,
      isSuccess: isSuccessUploadConversation,
      isError: isErrorUploadConversation
   }] = useUploadConversationMutation();

   const [updateProfesionalInfo, {
      data: profesionalData,
      isLoading: isLoadingUpdateProfesionalInfo,
      isSuccess: isSuccessUpdateProfesionalInfo,
      isError: isErrorUpdateProfesionalInfo
   }] = useUpdateProfesionalInfoMutation();

   const [triggerFetchSelfAssesment, {
      data: assesmentsData,
      isSuccess: isSuccessFetchAssesmentData,
      isError: isErrorFetchAssesmentData,
      isFetching: isFetchingAssesmentData,
   }] = useLazyFetchSelfAssementQuery();

   const {
      data: skill,
      isSuccess: isSuccessFetchSkill,
      isLoading: isLoadingSkill,
      isFetching: isFetchingSkill,
      refetch: refetchSkill
   } = useSkillQuery(
      {paramId: idSkill},
      {skip: true}
   );

   const [uploadSelfAssetment, {
      data: selfAssementDada,
      isLoading: isLoadingUploadSelfAssetment,
      isSuccess: isSuccessUploadSelfAssetment,
      isError: isErrorUploadSelfAssetment
   }] = useUploadSelfAssestmentMutation();

   const [uploadInterviewFeedBack, {
      data: uploadInterviewFeedBackData,
      isLoading: isLoadingUploadInterviewFeedBack,
      isSuccess: isSuccessUploadInterviewFeedBack,
      isError: isErrorUploadInterviewFeedBack
   }] = useUploadInterviewFeedBackMutation();

   return {
      //Interview feed back
      uploadInterviewFeedBack,
      isLoadingUploadInterviewFeedBack,
      isSuccessUploadInterviewFeedBack,
      //selfAssestments
      selfAssestments,
      uploadSelfAssetment,
      isSuccessUploadSelfAssetment,
      isLoadingUploadSelfAssetment,
      //Fetch skill
      skill,
      isSuccessFetchSkill,
      isLoadingSkill,
      isFetchingSkill,
      //contact info
      assesmentsData,
      isSuccessFetchAssesmentData,
      isErrorFetchAssesmentData,
      isFetchingAssesmentData,
      triggerFetchSelfAssesment,
      //Interaction IA
      transcripts,
      sendInteraction,
      interactionData,
      isLoadingInteraction,
      isSuccessInteraction,
      isErrorInteraction,
      currentSpeech,
      currentQuestion,
      //Upload conversation
      uploadConversation,
      conversationData,
      isLoadingUploadConversation,
      isSuccessUploadConversation,
      isErrorUploadConversation,
      //Update profesional Info
      updateProfesionalInfo,
      isLoadingUpdateProfesionalInfo,
      profesionalData,
      isSuccessUpdateProfesionalInfo,
      isErrorUpdateProfesionalInfo,
      // state contactInfo
      contactInfo,
      currentTime,
      progressBar,
      // data,
      // error,
      questions,
      totalQuestions,
      indexQuestion,
      inputAudioSelected,
      inputVideoSelelcted,
      dataVideo,
      errorVideo,
      isLoadingVideo,
      isSuccessVideo,
      isSuccessCompleteInterview,
      errorCompleteInterview,
      sendCompleteInterview,
      idSkill,
      testId,
      idInterView,
      idRecording,
      secret,
      idInterviewCompleted,
      secretInterviewCompleted,
      startInterview,
      userName,
      currentStep,
      responseInteraction,
      statusTimer,
      activeRecording,
      enableRoute,
      // isSuccessValidInterview,
      // isLoadingValidInterview,
      //fn
      // isErrorInterview,
      uploadVideo,
      setSkillId: (skillId) => dispatch(setSkillId(skillId)),
      setQuestions: (questions) => dispatch(setQuestions(questions)),
      resetTotalQuestions: () => dispatch(resetTotalQuestions()),
      setInterViewCredentials: (credentials) => dispatch(setInterViewCredentials(credentials)),
      setIndexQuestion: (index) => dispatch(setIndexQuestion(index)),
      setInputAudioSelected: (inputId) => dispatch(setInputAudioSelected(inputId)),
      setInputVideoSelected: (inputId) => dispatch(setInputVideoSelected(inputId)),
      setCompletedInterviewCredentials: (credentials) => dispatch(setCompletedInterviewCredentials(credentials)),
      setStartInterview: (state) => dispatch(setStartInterview(state)),
      setCurrentStep: (step) => dispatch(setCurrentStep(step)),
      setResponseInteraction: (response) => dispatch(setResponseInteraction(response)),
      setStatusInteractionFalse: (index) => dispatch(setStatusInteractionFalse(index)),
      setTranscripts: (transcript) => dispatch(setTranscripts(transcript)),
      setIdRecording: (recordingId) => dispatch(setIdRecording(recordingId)),
      setStatusTimer: (status) => dispatch(setStatusTimer(status)),
      setCurrentTime: (time) => dispatch(setCurrentTime(time)),
      setProgressBar: (progress) => dispatch(setProgressBar(progress)),
      setResetTranscripts: () => dispatch(setResetTranscripts()),
      setCurrentQuestion: (question) => dispatch(setCurrentQuestion(question)),
      setCurrentSpeech: (text) => dispatch(setCurrentSpeech(text)),
      setActiveRecording: (recording) => dispatch(setActiveRecording(recording)),
      setContactInfo: (contact) => dispatch(setContactInfo(contact)),
      setCredentials: (credentials) => dispatch(setCredentials(credentials)),
      setSelfAssestments: (assestments) => dispatch(setSelfAssestments(assestments)),
      setResetStep: (() => dispatch(setResetStep())),
      resetInterviewState: (() => dispatch(resetInterviewState()))
   }
}
export default useInterviewViewModel;
