import {useFieldArray, useForm} from "react-hook-form";
import useTestGeneratorViewModel from "../../view-models/admin/useTestGeneratorViewModel";
import {useEffect, useState} from "react";
import useAlertHook from "../../presentation/hooks/useAlertHook";
import {useParams, useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup"
import {MSG_ERROR, TEST_GENERATOR_ERRORS} from "../../data/constant/Constants";
import {ROUTE_ADMIN_TEST_GENERATOR} from "../../data/constant/RouteConstants";
import useAuthViewModel from "../../view-models/useAuthViewModel";

const useTestGeneratorController = () => {
   const navigate = useNavigate();
   const [indexQuestion, setIndexQuestion] = useState(null);
   const [discartChanges, setDiscartChanges] = useState(false);
   const [questionHistoryIsVisible, setQuestionHistoryIsVisible] = useState(false);
   const [excRegenQuestion, setExcRegenQuestion] = useState(false);
   const [indexHistory, setIndexHistory] = useState(null);
   const [selfAssessmentValue, setSelfAssessmentValue] = useState(null);
   const [addingSelfAssessment, setAddingSelfAssessment] = useState([]);

   const {skillId} = useParams();
   const {alert} = useAlertHook();
   const {
      skillData,
      setSkill,
      setRegenerateSkillId,
      setRegenSkillId,
      updateSkill,
      createSkill,
      regenerateQuestion,
      updateStatusSkill,
      data,
      dataUpdateSkill,
      dataRegenerate,
      isSuccess,
      isSuccessUpdate,
      isSuccessRegenerate,
      isSuccessFetchSkill,
      isError,
      paramId,
      skill,
      setSkillId,
      isLoading,
      isLoadingUpdate,
      isLoadingRegenerate,
      isLoadingSkill,
      isFetchingSkill,
      isFetchingRegenerate,
   } = useTestGeneratorViewModel();

   const {token} = useAuthViewModel();

   const schema = yup
      .object().shape({
         skillName: yup.string().required(MSG_ERROR[0]).max(50, TEST_GENERATOR_ERRORS[0]),
         seniority: yup.string().required(MSG_ERROR[0]),
         language: yup.string(),
         name: yup.string().required(MSG_ERROR[0]).max(100, TEST_GENERATOR_ERRORS[1]),
         skillType: yup.string().required(MSG_ERROR[0]),
         notes: yup.string().nullable().max(500, TEST_GENERATOR_ERRORS[2]),
         extraQuestions: yup.lazy(value => {
            if (value && value.length > 1) {
               return yup.array().of(
                  yup.object().shape({
                     value: yup.string().required(MSG_ERROR[0])
                  }))
            } else {
               return yup.array();
            }
         }),
         questions: yup.array(),
         active: yup.boolean(),
         selfAssessmentItems: yup.array()
            .of(yup.object().shape({
               value: yup
                  .string().required(MSG_ERROR[0])
            }))
            .required(MSG_ERROR[0])
            .min(1, MSG_ERROR[0]),
      })

   const {
      control,
      register,
      setValue,
      handleSubmit,
      formState: {errors}
   } = useForm({
      resolver: yupResolver(schema),
      mode: 'onChange'
   });

   const {fields, append, remove} = useFieldArray({
      control,
      name: "extraQuestions",
      rules: {required: true},
   });

   const {
      fields: selfAssessmentFields,
      append: appendSelfAssessment,
      remove: removeSelfAssessment
   } = useFieldArray({
      control,
      name: "selfAssessmentItems",
      rules: {required: true},
   });

   useEffect(() => {
      if (skillId) {
         setValue("skillName", skill?.data?.skillName);
         setValue("seniority", skill?.data?.seniority);
         setValue("name", skill?.data?.name);
         setValue("skillType", skill?.data?.skillType);
         setValue("notes", skill?.data?.notes);
         setValue("active", skill?.data?.active);

         setAddingSelfAssessment(skill?.data?.selfAssessmentItems);

         if (skill?.data?.selfAssessmentItems) {
            skill.data.selfAssessmentItems.forEach((el) => {
               appendSelfAssessment({value: el});
            });
         }

         skill?.data?.questions?.map((el, index) => {
            setValue(`questions.${index}.value`, el)
         })

         if (skill?.data?.extraQuestions) {
            skill?.data?.extraQuestions.forEach((el, index) => {
               append({value: ''});
               setValue(`extraQuestions.${index}.value`, el);
            });
         }
      }
   }, []);

   useEffect(() => {
      if (skillId) {
         setValue("skillName", skill?.data?.skillName);
         setValue("seniority", skill?.data?.seniority);
         setValue("name", skill?.data?.name);
         setValue("skillType", skill?.data?.skillType);
         setValue("notes", skill?.data?.notes);
         setValue("active", skill?.data?.active);

         skill?.data?.questions?.map((el, index) => {
            setValue(`questions[${index}].value`, el)
         })
         if (skill?.data?.extraQuestions) {
            skill?.data?.extraQuestions.forEach((el, index) => {
               append({value: ''});
               setValue(`extraQuestions.${index}.value`, el);
               remove(index + 1);
            });
         }
      }
   }, [discartChanges]);

   useEffect(() => {
      if (skillId) {
         setSkillId(skillId);
         setRegenSkillId(skillId);
      } else {
         setSkill([]);
         setSkillId(null)
      }
   }, [skillId]);

   useEffect(() => {
      if (skillId && isSuccessFetchSkill) {
         setSkill(skill);
         // setInitValues();
      }
   }, [isSuccessFetchSkill, skill, skillId]);

   useEffect(() => {
      if (isSuccess) {
         alert(true, false, 'Skill success create');
         navigate(`${ROUTE_ADMIN_TEST_GENERATOR.TEST_GENERATOR}/${data?.data?._id}`);
      }
      if (isSuccessUpdate) {
         alert(true, false, 'Skill success update');
         setSkill(dataUpdateSkill);
      }
   }, [isSuccess, isSuccessUpdate]);

   useEffect(() => {
      if (skillId) setRegenerateSkillId(skillId);
   }, []);

   useEffect(() => {
      if (skillId && isSuccessRegenerate) {
         setValue(`questions[${indexQuestion}].value`, dataRegenerate?.questionRemaked[0]);
      }
   }, [excRegenQuestion, indexQuestion, isSuccessRegenerate]);

   const onVisibleQuestionHistory = (index) => {
      setIndexHistory(index);
      setQuestionHistoryIsVisible(prevState => !prevState);
   }

   const onChangeStatusSkill = (status) => {
      updateStatusSkill({
         id: paramId,
         status: status.target.value
      });
   }

   const onDiscartChanges = () => {
      setDiscartChanges(prev => !prev);
   }

   const onRegenerateQuestion = (index) => {
      setIndexQuestion(index);
      regenerateQuestion({id: skillId})
      setExcRegenQuestion(prev => !prev)
   }

   const onDeleteExtraQuestionField = (index) => {
      remove(index);
   }

   const formatQuetion = (array) => {
      return array.map(el => {
         const newString = el.replace(/^\d+\.\s*/, '');
         return newString;
      })
   }

   const handleChangeValueSelfAssessment = (e) => {
      setSelfAssessmentValue(e.target.value);
   }

   const handleAppendSelfAssessment = () => {
      if (!selfAssessmentValue) return
      const existSelfAssessment = addingSelfAssessment?.some(el => el === selfAssessmentValue);
      if (addingSelfAssessment.length < 15) {
         if (!existSelfAssessment) {
            appendSelfAssessment({
               value: selfAssessmentValue
            })
            setAddingSelfAssessment((prev) => [...prev, selfAssessmentValue])
            setSelfAssessmentValue('')
         }
      }
   }

   const onHandleSubmit = (data) => {
      const questions = data.extraQuestions.map(el => el.value);
      let questionFormat = formatQuetion(questions);

      const newSelfAssessment = data.selfAssessmentItems.map(item => item.value);

      const {extraQuestions, active, selfAssessmentItems, ...skill} = data;
      skill.extraQuestions = questionFormat;
      skill.selfAssessmentItems = newSelfAssessment;


      if (!skillId) {
         createSkill(skill);
      } else {
         skill.id = skillId;
         let questions = data?.questions.map(el => el.value);
         let formatQuestions = formatQuetion(questions);
         skill.questions = formatQuestions;
         updateSkill(skill);
      }
   }

   const fetchPdf = async (id) => {
      // let test = candidateTests?.find(el => el?._id === idTest);
      try {
         const response = await fetch(`${process.env.REACT_APP_API_URL}/skill/test/pdf/${id}`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({reportType: 'detailed'})
         })

         const blob = await response.blob();
         const url = window.URL.createObjectURL(blob);
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `test.pdf`);
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);

      } catch (e) {
         throw new Error('Network response was not ok');
      }
   }

   return {
      skillData,
      control,
      fields,
      register,
      handleSubmit,
      append,
      createSkill,
      data,
      isSuccess,
      isError,
      skillId,
      errors,
      remove,
      indexQuestion,
      isLoading,
      isLoadingUpdate,
      isLoadingRegenerate,
      isLoadingSkill,
      isFetchingSkill,
      isFetchingRegenerate,
      questionHistoryIsVisible,
      setQuestionHistoryIsVisible,
      indexHistory,
      skill,
      //self assessment
      setSelfAssessmentValue,
      selfAssessmentValue,
      selfAssessmentFields,
      removeSelfAssessment,
      handleChangeValueSelfAssessment,
      handleAppendSelfAssessment,
      //
      onHandleSubmit,
      onDeleteExtraQuestionField,
      onRegenerateQuestion,
      onDiscartChanges,
      onChangeStatusSkill,
      onVisibleQuestionHistory,
      fetchPdf
   }
}
export default useTestGeneratorController;
