import { useDispatch, useSelector } from "react-redux";
import { setCounter, setStartChronometer } from "../store/features/chronometer-slice";

const useChronometerViewModel = () => {
    const dispatch = useDispatch();

    const { counter, start } = useSelector(state => state.ChronometerReducer)

    return {
        counter,
        start,
        setCounter: (counter => dispatch(setCounter(counter))),
        setStartChronometer: (state => dispatch(setStartChronometer(state)))
    }
}
export default useChronometerViewModel;
