import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   idSkill: null,
   candidates: [],
   candidateParam: '',
   skill: {},
   regenSkillId: null,
}

const testGenerateSlice = createSlice({
   name: 'testGenerateSlice',
   initialState,
   reducers: {
      setSkillId: (state, action) => {
         state.idSkill = action.payload;
      },
      setCandidates: (state, action) => {
         if (action.payload) {
            const candidate = state.candidates.find(el => el._id === action.payload?._id);
            if (!candidate) {
               state.candidates.push(action.payload);
            }
         }
      },
      removeCandidate: (state, action) => {
        state.candidates = state.candidates.filter(el => el?._id !== action.payload);
      },
      setCandidateParam: (state, action) => {
         state.candidateParam = action.payload;
      },
      setSkill: (state, action) => {
         state.skill = action.payload;
      },
      setRegenSkillId: (state, action) => {
        state.regenSkillId = action.payload;
      },
      resetState: (state, action) => {
         state.candidates = [];
         state.candidateParam = '';
      }
   }
})
export const {
   setSkillId,
   setCandidates,
   removeCandidate,
   setCandidateParam,
   setSkill,
   setRegenSkillId,
   resetState
} = testGenerateSlice.actions;
export default testGenerateSlice.reducer;
