import {Box, Typography} from "@mui/material";
import InputText from "../../../components/ui/InputText";
import Select from "../../../components/ui/Select";
import Label from "../../../components/ui/InputLabel";
import CustomButton from "../../../components/ui/Button";
import useTestGeneratorController from "../../../../view-controlllers/admin/useTestGeneratorController";
import {SELECT_TITLE, SENIORITY, SKILL_TYPE, STATUS} from "../../../../data/constant/Constants";
import ErrorLabel from "../../../components/ui/ErrorLabel";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import CustomTypography from "../../../components/ui/Typography";
import Loading from "../../../components/ui/Loading";
import QuestionHistory from "./QuestionHistory";
import Chip from "../../../components/ui/Chip/Chip";

const Form = ({params}) => {

   const {
      skillData,
      fields,
      register,
      append,
      handleSubmit,
      errors,
      isLoading,
      isLoadingUpdate,
      isLoadingRegenerate,
      indexQuestion,
      questionHistoryIsVisible,
      indexHistory,
      skill,
      //Self assessment
      handleAppendSelfAssessment,
      handleChangeValueSelfAssessment,
      selfAssessmentValue,
      selfAssessmentFields,
      removeSelfAssessment,
      //
      onVisibleQuestionHistory,
      onDeleteExtraQuestionField,
      onRegenerateQuestion,
      onHandleSubmit,
      onDiscartChanges,
      onChangeStatusSkill
   } = useTestGeneratorController();

   return (
      <form onSubmit={handleSubmit(data => onHandleSubmit(data))}>
         <Box sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'column',
         }}>
            <Box
               sx={{display: 'flex'}}
            >
               <Box mt={2} sx={{
                  flex: 1
               }}>
                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Skill #1*"
                     />
                     <InputText
                        defaulValue={params && skill?.data?.skillName}
                        disabled={params && true}
                        size="small"
                        style={{width: '350px'}}
                        {...register("skillName")}
                     />
                     <ErrorLabel
                        error={errors?.skillName}
                        message={errors.skillName?.message}
                     />
                  </Box>
                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Seniority*"
                     />
                     <Select
                        disabled={params && true}
                        size="small"
                        styles={{width: '350px'}}
                        data={SENIORITY}
                        defaultValue=""
                        selected={!params ? true : false}
                        title={SELECT_TITLE}
                        {...register("seniority")}
                     />
                     <ErrorLabel
                        error={errors?.seniority}
                        message={errors.seniority?.message}
                     />
                  </Box>
                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Language"
                     />
                     <Select
                        defaultValue={"en"}
                        size="small"
                        styles={{width: '350px'}}
                        selected={true}
                        title="English"
                        {...register("language")}
                     />
                  </Box>

                  {params &&
                     <Box
                        mb={2}
                        mt={2}
                        sx={{
                           display: 'flex',
                           justifyContent: 'space-between'
                        }}>
                        <Box>
                           <Label
                              style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                              title="Status"
                           />
                           <Select
                              name="active"
                              size="small"
                              styles={{width: '255px'}}
                              data={STATUS}
                              selected={true}
                              title={
                                 skill?.data?.active ? 'Active' : 'Inactive'
                              }
                              onSelectChange={onChangeStatusSkill}
                           />
                        </Box>
                     </Box>
                  }
               </Box>
               <Box
                  mt={2}
                  sx={{flex: 1}}
               >
                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Interview Name*"
                     />
                     <InputText
                        size="small"
                        fullWidth={true}
                        style={{width: '480px'}}
                        {...register("name")}
                     />
                     <ErrorLabel
                        error={errors?.name}
                        message={errors.name?.message}
                     />
                  </Box>
                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Skill type*"
                     />
                     <Select
                        disabled={params && true}
                        size="small"
                        data={SKILL_TYPE}
                        defaultValue=""
                        selected={!params ? true : false}
                        styles={{width: '255px'}}
                        title={SELECT_TITLE}
                        {...register("skillType")}
                     />
                     <ErrorLabel
                        error={errors?.skillType}
                        message={errors.skillType?.message}
                     />
                  </Box>

                  <Box mt={2}>
                     <Label
                        style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                        title="Self assestment*"
                     />
                     <Box display='flex' alignItems='center'>
                        <Box>
                           <InputText
                              size="small"
                              style={{width: '410px'}}
                              value={selfAssessmentValue}
                              onChange={handleChangeValueSelfAssessment}
                           />
                           <ErrorLabel
                              error={errors?.selfAssessmentItems}
                              message={errors?.selfAssessmentItems?.message}
                           />
                        </Box>
                        <Box>
                           <CustomButton
                              sx={{
                                 borderRadius: 50,
                                 marginLeft: 1
                              }}
                              variant='contained'
                              fullWidth={true}
                              title="ADD"
                              onPress={handleAppendSelfAssessment}
                           />
                        </Box>
                     </Box>
                     <Box mt={1}>
                        <>
                           {
                              selfAssessmentFields.map((field, index) => (
                                 <>
                                    <Chip
                                       index={index}
                                       label={field.value}
                                       onDelete={() => removeSelfAssessment(index)}
                                       sx={{
                                          backgroundColor: 'rgba(249, 79, 121, 0.32)',
                                          marginBottom: 1,
                                          mr: 1
                                       }}
                                    />
                                 </>
                              ))
                           }
                        </>
                     </Box>
                  </Box>
               </Box>
            </Box>
            <Box mt={2} sx={{flex: 1}}>
               <Label
                  style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                  title="Prompt"
               />
               <textarea
                  style={{
                     width: '100%',
                     height: '155px',
                     resize: 'vertical'
                  }}
                  {...register("notes")}
               />
               <ErrorLabel
                  error={errors?.notes}
                  message={errors.notes?.message}
               />
            </Box>

            {params && skillData?.data?.questions &&
               <Box sx={{
                  background: '#fafafa',
                  maxHeight: '500px',
                  overflowY: 'auto',
                  mt: 3,
                  mb: 3,
                  p: 2
               }}>
                  {skillData?.data?.questions.map((question, index) => (
                     <>
                        <Box
                           key={index + 1}
                           sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                           }}
                        >
                           <Label
                              title={`Question ${index + 1}`}
                              style={{
                                 color: '#000',
                                 fontWeight: 'bold',
                                 fontSize: '12px'
                              }}
                           />
                           <IconButton
                              disabled={isLoadingRegenerate}
                              color="primary"
                              style={{borderRadius: 10}}
                              onClick={() => onRegenerateQuestion(index)}
                           >
                              {isLoadingRegenerate && indexQuestion === index ?
                                 <Loading size={20} style={{mr: 1}}/>
                                 :
                                 <ReplayIcon color="primary.main"/>
                              }
                              <CustomTypography
                                 title="Regenerate"
                                 fontWeight={900}
                                 fontSize={12}
                                 color={"primary.main"}
                              />
                           </IconButton>
                        </Box>
                        <Box
                           key={index}
                           sx={{
                              mt: 1,
                           }}
                        >
                           <InputText
                              {...register(`questions.${index}.value`)}
                              defaulValue={question}
                              fullWidth={true}
                           />
                        </Box>
                        <QuestionHistory
                           isVisible={questionHistoryIsVisible}
                           data={skillData?.data}
                           indexQuestion={index}
                           onClick={() => onVisibleQuestionHistory(index)}
                           indexHistory={indexHistory}
                        />
                     </>
                  ))}
               </Box>
            }

            <Box mt={2} width="102%">
               <Label
                  style={{fontSize: '12px', fontWeight: 'bold', color: '#000'}}
                  title="Add extra-questions or notes"
               />

               {fields?.map((field, index) => (
                  <Box sx={{display: 'flex', mt: 2, flexDirection: 'column'}} key={field.id}>
                     <Box sx={{display: 'flex'}}>
                        <InputText
                           size="small"
                           fullWidth={true}
                           {...register(`extraQuestions.${index}.value`)}
                        />
                        <IconButton
                           onClick={() => onDeleteExtraQuestionField(index)}
                           style={{cursor: 'pointer'}}
                        >
                           <DeleteIcon color="error"/>
                        </IconButton>
                     </Box>
                     {errors?.extraQuestions &&
                        <ErrorLabel
                           error={errors?.extraQuestions[index]}
                           message={errors.extraQuestions[index]?.value?.message}
                        />
                     }
                  </Box>
               ))}
            </Box>
            <Typography
               onClick={() => append({test: 'test'})}
               sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: 'primary.main',
                  mt: 2,
                  cursor: 'pointer'
               }}
            >
               Add another question
            </Typography>
         </Box>

         <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 7
         }}>

            {!params ?
               <CustomButton
                  title="GENERATE"
                  variant="contained"
                  style={{borderRadius: 50}}
                  size="small"
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
               /> :

               <Box sx={{display: 'flex'}}>
                  <CustomButton
                     title="SAVE INTERVIEW"
                     variant="contained"
                     style={{borderRadius: 50}}
                     size="small"
                     isDisabled={isLoadingUpdate}
                     isLoading={isLoadingUpdate}
                     type="submit"
                  />
                  <CustomButton
                     title="DISCART INTERVIEW"
                     variant="contained"
                     style={{borderRadius: 50, marginLeft: 20}}
                     size="small"
                     color="error"
                     onPress={() => onDiscartChanges()}
                  />
               </Box>
            }
         </Box>
      </form>
   )
}
export default Form;
