import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

const InputSearch = ({onChange, title, styles, size}) => {
   return (
      <Paper
         elevation={0}
         component="form"
         sx={{
            p: 1,
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #d7d7d7',
            ...styles
      }}
      >
         <InputBase
            onChange={(e) => onChange(e.target.value)}
            sx={{ ml: 1, flex: 1}}
            placeholder={title}
            inputProps={{ 'aria-label': {title} +
                  '' }}
         />
         <IconButton
            type="button"
            size={size}
            sx={{
               bgcolor: 'secondary.main',
               color: '#fff',
               borderRadius: 2,
         }}
            aria-label="search">
            <SearchIcon
            />
         </IconButton>
      </Paper>
   )
}
export default InputSearch;
