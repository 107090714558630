import CustomTabs from "./Tabs";


const TestManagement = () => {

   return (
      <CustomTabs />
   )
}
export default TestManagement;
