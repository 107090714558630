import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {intersection} from "lodash/array";
import {logout, setCredentials} from "../../features/auth-slice";
import {LANGUAGE} from "../../../data/constant/Constants";

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, {getState}) => {
        const token = getState().AuthReducer?.token;
        if (token) headers.set('authorization', `Bearer ${token}`)
        headers.set(['userSignature'], 'f0e00f86f5dfbbc90cf36b8f3b7ed272f3aa76dc3eaa9bcc24114e3c39457495');
        headers.set('X-Locale', LANGUAGE[1])
        return headers
    }
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (intersection([result?.error?.originalStatus, result?.error?.status], [401, 410]).length > 0) {
        api.dispatch(logout());
    } else if (result?.meta?.response?.headers?.get('token')) {
        api.dispatch(setCredentials({ data: { token: result?.meta?.response?.headers?.get('token')}}))
    }

    return result;
}
