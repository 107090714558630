import useNotifyViewModel from "../../../../view-models/useNotifyViewModel";
import { Typography, Box } from "@mui/material";
import useChronometerViewModel from "../../../../view-models/useChronometerViewModel";
import Loading from "../Loading";

const Notify = () => {
    const {isActive, message, isError, isLoading} = useNotifyViewModel();
    const {counter} = useChronometerViewModel();

    // const title = isError ? 'Error' : 'Success';

    if (!isActive) return null;

    return (
        <Box sx={{
            bgcolor: '#2C5F1A',
            padding: 1,
            width: 900,
            height: 96,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            borderRadius: 1,
        }}>
            {
                !isError ?

                    <>
                        <Typography
                            color="#fff"
                        >
                            {message} <>{!isLoading && counter}</>
                        </Typography>

                        <Box ml={1}>
                            {isLoading &&  <Loading />}
                        </Box>
                    </>

                    :
                    <Typography
                     color="#fff"
                    >
                        {message}
                    </Typography>
            }
        </Box>
    )
}
export default Notify;
