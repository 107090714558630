import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BreadcrumbContext = createContext();

export const BreadcrumbProvider = ({ children }) => {
   const location = useLocation();
   const [previousPath, setPreviousPath] = useState(null);

   useEffect(() => {
      return () => setPreviousPath(location.pathname);
   }, [location]);

   return (
      <BreadcrumbContext.Provider value={{ previousPath }}>
         {children}
      </BreadcrumbContext.Provider>
   );
};

export const useBreadcrumb = () => useContext(BreadcrumbContext);
