import {InputLabel} from "@mui/material";

const Label = ({style, title, color, sx}) => {
   return (
      <InputLabel style={style} color={color} sx={sx}>
         {title}
      </InputLabel>
   )
}
export default Label;
