import useAppContext from "./useAppContext";

const useAlertHook = () => {
   const { onSetOpenAlert } = useAppContext();

   const alert = (isActive, isError = false, message, severity) => {
      onSetOpenAlert({
         isActiveAlert: isActive,
         errorAlert: isError,
         messageAlert: message,
         severityAlert: severity
      })
   };
   return { alert }
}
export default useAlertHook;
