import {Box, CircularProgress} from "@mui/material";

const Loading = ({size, style}) => {
   return (
      <Box sx={{display: 'flex'}}>
         <CircularProgress size={size} sx={style} />
      </Box>
   )
}
export default Loading;
