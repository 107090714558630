import './App.css';
import Router from "./presentation/routes";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./presentation/styles/theme";
import {Provider} from "react-redux";
import {persistor, store} from "./store/app/store";
import {PersistGate} from "redux-persist/integration/react";
import Notify from "./presentation/components/ui/Notify";
import {Box} from "@mui/material";
import AppContextProvider from "./store/context/AppContextProvider";
import SpeechContextProvider from "./store/context/SpeechContextProvider";
import RecordingContextProvider from "./store/context/RecordingContextProvider";
import {useEffect} from "react";

function App() {

   useEffect(() => {
      const redirectPath = localStorage.getItem('redirectAfterReload');
      if (redirectPath) {
         localStorage.removeItem('redirectAfterReload');
         window.location.href = redirectPath;
      }
   }, []);

   return (
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
            <AppContextProvider>
               <RecordingContextProvider>
                  <SpeechContextProvider>
                     <ThemeProvider theme={theme}>
                        <Box sx={{display: 'flex'}}>
                           <Notify/>
                        </Box>
                        <Router/>
                     </ThemeProvider>
                  </SpeechContextProvider>
               </RecordingContextProvider>
            </AppContextProvider>
         </PersistGate>
      </Provider>
   );
}

export default App;
