import { useDispatch, useSelector } from "react-redux";
import { clearNotify, updataNotify } from "../store/features/notify-slice";

const useNotifyViewModel = () => {

    const dispatch = useDispatch();

    const {
        isActive,
        message,
        isError,
        isLoading
    } = useSelector(state => state.NotifyReducer)

    return {
        isActive,
        message,
        isError,
        isLoading,
        updataNotify: (state => dispatch(updataNotify(state))),
        clearNotify: (state => dispatch(clearNotify(state)))
    }
}
export default useNotifyViewModel;
