import React from "react";
import CustomSearch from "../Search";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Loading from "../Loading";
import {Box} from "@mui/material";

const SearchList = ({data, onChange, onClick, isFetching}) => {

   return (
      <div>
         <CustomSearch
            onChange={onChange}
         />

         {isFetching ?
            <Box display="flex" justifyContent="center" p={2}>
               <Loading/>
            </Box>
            :
            <>
               {data?.length > 0 && (
                  <ul style={{
                     maxHeight: '150px',
                     overflowY: 'auto',
                     border: '1px solid #ccc',
                     padding: '0',
                     margin: '0'
                  }}>
                     {data?.map((result, index) => (
                        <ListItem key={result?.id} component="div" disablePadding disableGutters>
                           <ListItemButton key={result?.id} onClick={() => onClick(result)} alignItems="center">
                              <ListItemText key={result?.id} primary={`Name: ${result?.name}`}
                                            secondary={`Email: ${result?.email}`}/>
                           </ListItemButton>
                        </ListItem>
                     ))}
                  </ul>
               )}
            </>
         }
      </div>
   );
};
export default SearchList;
