import {Typography} from "@mui/material";

const CustomTypography = ({
                             title,
                             style,
                             sx,
                             fontSize,
                             color,
                             fontWeight,
                             textTransform,
                             lineHeight,
                             fontStyle,
                             onClick,
                          }) => {
   return (
      <Typography
         sx={sx}
         onClick={onClick}
         style={style}
         fontSize={fontSize}
         color={color}
         fontWeight={fontWeight}
         textTransform={textTransform}
         lineHeight={lineHeight}
         fontStyle={fontStyle}
      >
         {title}
      </Typography>
   )
}
export default CustomTypography;
