import {createContext, useMemo, useState} from "react";

export const RecordingContext = createContext({
   isRecording: false,
   setRecordingState: () => null,
});

const RecordingContextProvider = ({children}) => {
   const [isRecording, setIsRecording] = useState(false);

   const setRecordingState = (state) => {
      setIsRecording(state)
   }

   const contextRecording = useMemo(() => ({
      isRecording,
      setRecordingState
   }), [
      isRecording
   ])

   return <RecordingContext.Provider value={contextRecording}>{children}</RecordingContext.Provider>
}
export default RecordingContextProvider;
