
export const ROUTE_ENDPOINTS = {
   LOGIN: '/',
   FORGOT_PASSWORD: '/forgot-password',
   CHECK_INBOX: '/check-inbox',
   RESET_PASSWORD: '/reset-password/:code',
   TEST_LANDING: '/interview',
   TEST_LANDING_SKILL: '/interview/:skillId',
   TEST_VIDEO: '/test-video',
   KEY_INDICATIONS: '/key-indications',
   TERMS_AND_CONDITIONS: '/terms-and-conditions',
   QUESTIONS_ON_HOLD: '/Questions-on-hold',
   RECORDING_INTERVIEW: '/recording-interview',
   TEST_COMPLETED: '/test-complete',
   PERSONAL_BASIC_INFO: '/personal-basic-info',
   PROFESSIONAL_INFO: '/verify-account',
   SELF_ASSESMENT: '/self-assesment',
   INTERVIEW_FEEDBACK: '/interview-feedback',
   SHOW_ABOUTME_INTERVIEW: '/show-aboutme-interview'
}

export const ROUTE_INTERVIEW = {
   TEST_LANDING: '/interview',
   TEST_LANDING_SKILL: '/interview/:skillId',
   TEST_VIDEO: '/interview/test-video',
   KEY_INDICATIONS: '/interview/key-indications',
   TERMS_AND_CONDITIONS: '/interview/terms-and-conditions',
   QUESTIONS_ON_HOLD: '/interview/Questions-on-hold',
   RECORDING_INTERVIEW: '/interview/recording-interview',
   TEST_COMPLETED: '/interview/test-complete',
   PERSONAL_BASIC_INFO: '/interview/personal-basic-info',
   PROFESSIONAL_INFO: '/interview/verify-account',
   SELF_ASSESMENT: '/interview/self-assesment',
   INTERVIEW_FEEDBACK: '/interview/interview-feedback',
   SHOW_ABOUTME_INTERVIEW: '/interview/show-aboutme-interview'
}

export const ROUTE_ADMIN_TEST_GENERATOR = {
   TEST_LIST: '/interviews',
   TEST_GENERATOR: '/admin-interview',
   TEST_GENERATOR_UPDATE: '/admin-interview/:skillId',
}

export const ROUTE_ADMIN_CANDIDATE = {
   DASHBOARD: '/dashboard',
   CANDIDATE_LIST: '/candidates',
   CANDIDATE: '/candidate',
   CANDIDATE_DETAIL: '/candidate/:candidateId',
   CANDIDATE_TEST: '/candidate/test-result',
   CANDIDATE_TEST_RESULTS: '/candidate/test-result/:testId'
}

export const routeNames = {
   '/dashboard': 'Dashboard',
   '/interviews': 'Interviews',
   '/admin-interview': 'Interview',
   '/admin-interview/:skillId': 'Interview',
   '/candidates': 'Candidates',
   '/candidate': 'Candidate',
};
