import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    counter: 60,
    start: false
}

const chronometerSlice = createSlice({
    name: 'chronometerSlice',
    initialState,
    reducers: {
        setCounter: (state, action) => {
            state.counter = action.payload;
        },
        setStartChronometer: (state,  action) => {
            state.start = action.payload;
        }
    }
})

export const {
    setCounter,
    setStartChronometer
} = chronometerSlice.actions;
export default chronometerSlice.reducer;
