import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useAppContext from "../../../hooks/useAppContext";

const CustomModal = ({styles, children}) => {

   const { openModal } = useAppContext();

   return (
         <Modal
            open={openModal}
            // onClose={() => setOpenModalState(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={styles}>
               {children}
            </Box>
         </Modal>
   )
}
export default CustomModal;
