import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Link} from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import React from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PreviewIcon from '@mui/icons-material/Preview';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {ROUTE_ADMIN_CANDIDATE} from "../../../../data/constant/RouteConstants";
import IconButton from "@mui/material/IconButton";
import useAppliedCandidatesController from "../../../../view-controlllers/admin/useAppliedCandidatesController";
import CustomTypography from "../../../components/ui/Typography";

dayjs.extend(utc);

const AppliedCandidates = ({
                              data,
                           }) => {

   const {onHandleDetailApplicant, fetchPdf} = useAppliedCandidatesController();

   console.log("data", data)

   return (
      <TableContainer component={Paper} style={{overflowX: 'auto', width: '100%'}} sx={{my: 2}}>
         <Table aria-label="simple table" style={{minWidth: 700}}>
            <TableHead>
               <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Candidate</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}} align="center">Applied Date</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}} align="center">Test Result</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}} align="center">Report</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}} align="center">View Interview</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {
                  data.length > 0 ?
                     <>
                        {
                           data?.map((row, index) => (
                              <TableRow key={index}>
                                 <TableCell align="left">
                                    <Link
                                       style={{textDecoration: 'none'}}
                                       to={`${ROUTE_ADMIN_CANDIDATE.CANDIDATE}/${row?.userId}`}
                                    >
                                       {row.name}
                                    </Link>
                                 </TableCell>
                                 <TableCell align="center">
                                    {
                                       row?.interviewDateTaken &&
                                       dayjs(row?.interviewDateTaken).utc().format('DD/MM/YYYY')
                                    }
                                 </TableCell>
                                 {/*<TableCell align="center"> {Number.isFinite(row?.score[0]?.globalRating?.obtained)*/}
                                 {/*   ? Math.round(row.score[0].globalRating.obtained) / 10*/}
                                 {/*   : ""}</TableCell>*/}
                                 <TableCell align="center">
                                    {row?.status === "Finished" || row?.status === "In Review"
                                       ? `${Math.round(row?.score[0]?.globalRating?.obtained)} / 10`
                                       : row?.status}
                                 </TableCell>
                                 <TableCell align="center">
                                    <IconButton
                                       disabled={
                                          row?.status === "Finished" || row?.status === "In Review" ? false : true
                                       }
                                    >
                                       <FileDownloadIcon
                                          sx={{
                                             cursor: 'pointer',
                                          }}
                                          onClick={() => fetchPdf(row?._id, row?.name)}
                                       />
                                    </IconButton>
                                 </TableCell>
                                 <TableCell align="center">
                                    <IconButton
                                       disabled={row?.status === "Finished" || row?.status === "In Review" ? false : true}
                                    >
                                       <PreviewIcon
                                          sx={{
                                             cursor: 'pointer',
                                          }}
                                          onClick={() => onHandleDetailApplicant(row?.userId)}
                                       />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           ))
                        }
                     </>
                     : (
                        <TableRow>
                           <TableCell colSpan={5} align="center">
                              <CustomTypography
                                 title="No results found for your search."
                                 sx={{padding: '20px 0'}}
                              />
                           </TableCell>
                        </TableRow>
                     )
               }
            </TableBody>
         </Table>
      </TableContainer>
   )
}
export default AppliedCandidates;
