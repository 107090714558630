import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   testId: null,
   secret: null,
   idSkill: null,
   idInterView: null,
   availableTests: [],
   questions: [],
   indexQuestion: 0,
   totalQuestions: null,
   idRecording: null,
   inputAudioSelected: null,
   inputVideoSelelcted: null,
   idInterviewCompleted: null,
   secretInterviewCompleted: null,
   startInterview: false,
   userName: '',
   currentStep: 0,
   responseInteraction: [],
   transcripts: [],
   currentQuestion: null,
   currentSpeech: null,
   statusTimer: true,
   currentTime: 300,
   progressBar: 0,
   activeRecording: null,
   selfAssestments: [],
   enableRoute: false,
   interviewInfo: {}
}

const interviewSlice = createSlice({
   name: 'interviewSlice',
   initialState,
   reducers: {
      setSkillId: (state, action) => {
         console.log('action payload', action.payload)
         state.idSkill = action.payload;
      },
      setInterViewCredentials: (state, action) => {
         state.userName = action.payload.userName;
         state.idSkill = action.payload.idSkill;
      },
      setQuestions: (state, action) => {
         state.questions = action.payload;
      },
      setIndexQuestion: (state, action) => {
         state.indexQuestion = action.payload;
      },
      setTotalQuestions: (state, action) => {
         state.totalQuestions = action.payload;
      },
      resetTotalQuestions: (state, action) => {
         state.totalQuestions = null;
      },
      setIdRecording: (state, action) => {
         state.idRecording = action.payload;
      },
      setInputAudioSelected: (state, action) => {
         state.inputAudioSelected = action.payload;
      },
      setInputVideoSelected: (state, action) => {
         state.inputVideoSelelcted = action.payload;
      },
      setCompletedInterviewCredentials: (state, action) => {
         state.idInterviewCompleted = action.payload.idInterviewCompleted;
         state.secretInterviewCompleted = action.payload.secretInterviewCompleted;
      },
      setStartInterview: (state, action) => {
         state.startInterview = action.payload;
      },
      setCurrentStep: (state, action) => {
         state.currentStep = Math.min(state.currentStep + 1, action.payload);
      },
      setResetStep: (state, action) => {
         state.currentStep = 0
      },
      setResponseInteraction: (state, action) => {
         if (action.payload.length === 0) {
            state.responseInteraction = action.payload;
         } else if (state.responseInteraction.length < 2) {
            if (state.responseInteraction.length > 0) {
               state.responseInteraction[state.responseInteraction.length - 1].isActiveAnimation = false;
            }
            state.responseInteraction.push(action.payload);
         }
      },
      setStatusInteractionFalse: (state, action) => {
         const index = action.payload;
         if (state.responseInteraction[index]) {
            state.responseInteraction[index].isActiveAnimation = false;
         }
      },
      setTranscripts: (state, action) => {
         if (action.payload != '') {
            state.transcripts.push(action.payload);
         } else state.transcripts.push("");
      },
      setCurrentQuestion: (state, action) => {
         state.currentQuestion = action.payload;
      },
      setCurrentSpeech: (state, action) => {
         state.currentSpeech = action.payload;
      },
      setResetTranscripts: (state, action) => {
         state.transcripts = initialState.transcripts;
      },
      setStatusTimer: (state, action) => {
         state.statusTimer = action.payload;
      },
      setCurrentTime: (state, action) => {
         state.currentTime = action.payload;
      },
      setProgressBar: (state, action) => {
         state.progressBar = action.payload;
      },
      setActiveRecording: (state, action) => {
         state.activeRecording = action.payload;
      },
      setSelfAssestments: (state, action) => {
        state.selfAssestments = action.payload
      },
      setTestCredentials: (state, action) => {
         state.testId = action.payload.testId;
         if (action.payload.secret) {
            state.secret = action.payload.secret;
         }
      },
      setAvailableTests: (state, action) => {
         state.availableTests = action.payload;
      },
      setEnableRoute: (state, action) => {
         state.enableRoute = action.payload;
      },
      setInterviewInfo: (state, action) => {
         state.interviewInfo = action.payload;
      },
      resetInterviewState: () => {
         return initialState;
      }
   }
});

export const {
   setSkillId,
   setQuestions,
   setInterViewCredentials,
   setIndexQuestion,
   setTotalQuestions,
   resetTotalQuestions,
   setIdRecording,
   setInputAudioSelected,
   setInputVideoSelected,
   setCompletedInterviewCredentials,
   setStartInterview,
   setCurrentStep,
   setResetStep,
   setResponseInteraction,
   setStatusInteractionFalse,
   setTranscripts,
   setCurrentQuestion,
   setCurrentSpeech,
   setStatusTimer,
   setProgressBar,
   setCurrentTime,
   setResetTranscripts,
   setActiveRecording,
   setSelfAssestments,
   setTestCredentials,
   setAvailableTests,
   setEnableRoute,
   setInterviewInfo,
   resetInterviewState
} = interviewSlice.actions;
export default interviewSlice.reducer;
