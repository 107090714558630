import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isActive: false,
    message: '',
    isError: false,
    isLoading: false
}

const notifySlice = createSlice({
    name: 'notifySlice',
    initialState,
    reducers: {
        updataNotify: (state, action) => {
            state.isActive = action.payload.isActive;
            state.message = action.payload.message;
            state.isError = action.payload.isError;
            state.isLoading = action.payload.isLoading;
        },
        clearNotify: () => {
            return initialState;
        }
    }
})

export const {
    updataNotify,
    clearNotify
} = notifySlice.actions;
export default notifySlice.reducer;
