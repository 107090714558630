import {useState} from "react";
import {
   useAddCandidateMutation,
   useCreateSkillMutation,
   useFetchUsersQuery,
   useSkillQuery,
   useSkillsQuery,
   useStatusSkillMutation,
   useUpdateSkillMutation,
   useRegenerateSkillMutation,
} from "../../store/app/apiSlice/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {
   setSkillId,
   setCandidates,
   resetState,
   setCandidateParam,
   removeCandidate,
   setRegenSkillId,
   setSkill,
} from "../../store/features/admin/test-generate-slice";

const useTestGeneratorViewModel = () => {

   const dispatch = useDispatch();

   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [paramId, setParamiId] = useState(null);
   const [regenerateSkillId, setRegenerateSkillId] = useState(null);
   const [searchCriteria, setSearchCriteria] = useState('');

   const {
      idSkill,
      candidates,
      candidateParam,
      regenSkillId,
      skill: skillData,
   } = useSelector(state => state.TestGenerateReducer);

   const {
      data: skill,
      isSuccess: isSuccessFetchSkill,
      isLoading: isLoadingSkill,
      isFetching: isFetchingSkill,
      refetch: refetchSkill
   } = useSkillQuery({paramId: idSkill});

   const [regenerateQuestion, {
      data: dataRegenerate,
      isSuccess: isSuccessRegenerate,
      isLoading: isLoadingRegenerate,
      isError: isErrorRegenerate,
   }] = useRegenerateSkillMutation();

   const [createSkill, {
      data,
      isLoading,
      isSuccess,
      isError
   }] = useCreateSkillMutation();

   const {
      data: skills,
      isLoading: isLoadingSkills,
      isFetching: isFetchingSkills,
      isSuccess: isSuccessFetchSkills,
      isError: isErrorFetchSkills,
      refetch: refetchSkills
   } = useSkillsQuery({
      page: page,
      limit: limit,
      ...(searchCriteria && { searchCriteria: searchCriteria })
   })

   const [updateSkill, {
      data: dataUpdateSkill,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      isLoading: isLoadingUpdate,
   }] = useUpdateSkillMutation();

   const {
      data: userFound,
      isLoading: isLoadingFoundUsers,
      isFetching: isFetchingFoundUser,
      isSuccess: isSuccessFoundUser,
      isError: isErrorFoundUser,
   } = useFetchUsersQuery({
      ...(candidateParam && {query: candidateParam})
   });

   const [addCandidate, {
      data: dataCandidates,
      isLoading: isLoadingAddCandidate,
      isSuccess: isSuccessAddCandiate,
      isError: isErrorAddCandidate,
      error: errorsAddCandidates
   }] = useAddCandidateMutation();

   const [updateStatusSkill, {
      isSuccess: isSuccessUpdateSkill,
   }] = useStatusSkillMutation();

   return {
      //state
      skillData,
      candidates,
      addCandidate,
      regenerateSkillId,
      updateSkill,
      createSkill,
      updateStatusSkill,
      data,
      dataCandidates,
      userFound,
      dataUpdateSkill,
      regenerateQuestion,
      dataRegenerate,
      regenSkillId,
      isSuccess,
      isSuccessUpdate,
      isSuccessRegenerate,
      isSuccessFoundUser,
      isSuccessAddCandiate,
      isSuccessUpdateSkill,
      isSuccessFetchSkill,
      isError,
      isErrorUpdate,
      isErrorFoundUser,
      isErrorAddCandidate,
      isErrorFetchSkills,
      errorsAddCandidates,
      setLimit,
      setPage,
      page,
      skills,
      skill,
      limit,
      isLoading,
      isLoadingSkills,
      isLoadingUpdate,
      isLoadingAddCandidate,
      isLoadingRegenerate,
      isLoadingFoundUsers,
      isLoadingSkill,
      isFetchingSkills,
      isFetchingFoundUser,
      isFetchingSkill,
      setParamiId,
      setRegenerateSkillId,
      paramId,
      idSkill,
      setSearchCriteria,
      refetchSkill,
      refetchSkills,
      setSkillId: ((skillId) => dispatch(setSkillId(skillId))),
      setCandidates: ((candidates) =>  dispatch(setCandidates(candidates))),
      resetState: (() => dispatch(resetState())),
      removeCandidate: ((id) => dispatch(removeCandidate(id))),
      setCandidateParam: ((param) => dispatch(setCandidateParam(param))),
      setSkill: ((data) => dispatch(setSkill(data))),
      setRegenSkillId: ((id) => dispatch(setRegenSkillId(id))),
   }
}
export default useTestGeneratorViewModel;
