import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {
   persistStore,
   persistReducer,
   FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER,
} from 'redux-persist';

import {apiSlice} from "./apiSlice/apiSlice";
import interViewReducer from "../features/interview-slilce";
import notifyReducer from "../features/notify-slice";
import chronometerReducer from "../features/chronometer-slice";
import authReducer from "../features/auth-slice";
import testGenerateReducer from "../features/admin/test-generate-slice";
import candidateReducer from "../features/admin/candidates-slice";
import credentialsInterviewReducer from "../features/credentialsInterview-slice";
import contactInfoReducer from "../features/contactInfo-slice";

import storage from 'redux-persist/lib/storage';

const persistConfig = {
   key: 'auth',
   version: 1,
   storage,
   whitelist: [
      'InterviewReducer',
      'NotifyReducer',
      'ChronometerReducer',
      'AuthReducer',
      'TestGenerateReducer',
      'CandidatesReducer',
      'CredentialsInterviewReducer',
      'ContactInfoReducer'
   ],
};

const rootReducer = combineReducers({
   InterviewReducer: interViewReducer,
   NotifyReducer: notifyReducer,
   ChronometerReducer: chronometerReducer,
   AuthReducer: authReducer,
   TestGenerateReducer: testGenerateReducer,
   CandidatesReducer: candidateReducer,
   CredentialsInterviewReducer: credentialsInterviewReducer,
   ContactInfoReducer: contactInfoReducer,
   [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
         },
      }).concat(apiSlice.middleware),
   devTools: true,
})

export const persistor = persistStore(store);
