import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   contactInfo: {},
   idSkill: null,
   enableRoute: false,
   credentials: {}
}

const contactInfoSlice = createSlice({
   name: 'contactInfoSlice',
   initialState,
   reducers: {
      setContactInfo: (state, action) => {
         state.contactInfo = action.payload;
      },
      setSkill: (state, action) => {
         state.idSkill = action.payload;
      },
      setEnableRoute: (state, action) => {
         state.enableRoute = action.payload
      },
      setCredentials: (state , action) => {
         state.credentials = action.payload;
      }
   }
})
export const {
   setContactInfo,
   setSkill,
   setEnableRoute,
   setCredentials,
} = contactInfoSlice.actions;
export default contactInfoSlice.reducer;
