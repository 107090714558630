import {Box} from "@mui/material";
import Form from "./Form";
import CustomTypography from "../../../components/ui/Typography/Typography";
import CustomButton from "../../../components/ui/Button";
import CustomAlert from "../../../components/ui/Alert";
import SendTest from "../TetsList/SendTest";
import Loading from "../../../components/ui/Loading";
import useTestListController from "../../../../view-controlllers/admin/useTestListController";

const TestGenerator = ({skillId, isFetchingSkill}) => {

   const {openAddCandidateModal} = useTestListController();

   return (
      <Box pb={4} mb={2}>
         {skillId && isFetchingSkill ?
            <Box sx={{
               display: 'flex',
               flex: 1,
               height: '50vh',
               alignItems: 'center',
               justifyContent: 'center'
            }}>
               <Loading/>
            </Box> :
            <>
               <SendTest/>
               <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 6,
               }}>
                  <CustomTypography
                     title="Generator Interview"
                     fontSize="24px"
                     fontWeight="700"
                  />
                  {skillId &&
                     <CustomButton
                        title="SEND INTERVIEW"
                        isDisabled={false}
                        variant="contained"
                        style={{borderRadius: 50}}
                        size="medium"
                        onPress={() => openAddCandidateModal(skillId, true)}
                     />
                  }
               </Box>
               <CustomAlert
                  variant="filled"
                  severity="success"
                  style={{mt: 2}}
               />
               <Form
                  params={skillId}
               />
            </>
         }
      </Box>
   )
}
export default TestGenerator;
