// export const BASE_URL = 'http://localhost:80/api/v1';
// export const QA_URL = 'https://rai-api-dev.softonitg.com/api/v1';
// export const NOTIFY_TIME = 3000;
export const NOTIFY_MSG= ['was Successfully submitted, next question in', 'Your question has been successfully uploaded'];
export const READ_QUESTION_TIME = 6;
export const ANSWER_TIME = 300;
export const NEXT_QUESTION_TIME = 5;

export const BROWSER = ['Chrome', 'Safari', 'Firefox', 'Microsoft Edge'];

export const CODEC_FIREFOX = 'video/webm';
export const CODEC_SAFARI = 'video/mp4;codecs=avc1';
export const CODEC_CHROME = 'video/webm;codecs=h264';
export const CODEC_EDGE = 'video/webm;codecs=vp9';
export const SENIORITY = [{label: 'Junior', value: 'Junior'}, {label: 'Mid', value: 'Mid'}, {label: 'Senior', value: 'Senior'}];
export const STATUS = [{label: 'Active', value: 'active'}, {label: 'Inactive', value: 'inactive'}]
export const MSG_ERROR = ['Required', 'Please enter a valid email address', 'The passwords do not match', 'Reset password token has expired'];
export const MSG_SUCCESS = ['Your password has been changed successfully.'];
export const SKILL_TYPE = [{label: 'Technical', value: 'Technical'}, {label: 'Soft', value: 'Soft'}];
export const SELECT_TITLE = 'Select an Option';
export const TEST_GENERATOR_ERRORS = ['Must be at most 50 characters', 'Must be at most 100 characters', 'Must be at most 500 characters']
export const SEARCH_ERROR = 'No results found for your search.';
export const LANGUAGE = ['es', 'en'];
export const LANGUAGES = [{label: 'English', value: 'es'},{label: 'Spanish', value: 'en'}]
export const ERROR_PASSWORD = [
   'At least 8 characters',
   'Password must contain at least 8 characters, ' +
   'including one uppercase letter, one number, and one special character.',
   'isValidPass!'
]

export const REGEX_ONLY_NUMBERS = /^\d+$/;
export const REGEX_ONLY_LETTERS = /^[aA-zZ\s]+$/;
export const ERROR_ONLY_LETTERS = 'Only letters are allowed';
export const ERROR_ONLY_NUMBERS = 'Only numbers are allowed';
export const ERROR_ONLY_WHOLE_NUMBERS = 'Only whole numbers are allowed';
export const TYPE_FUNCTION_INTERACTION_INTERVIEW = ["Next", "send question"];
export const STATUS_RECORDING = ['RECORDING'];
export const STATUS_QUESTION = ["There is not Response"];
export const SPEECH_STATUS = ['started', 'stopped'];
export const BUTTON_VIEW_TRANSCRIPTS = ['View video Transcript', 'Hide video Transcript'];

export const MSG_CODE_ERROR = 'This candidate has already taken this test'

export const USER_TYPE = {
   ADMIN: 'admin',
}

export const CAPTCHA_KEY = '6LfVwlkqAAAAAK4aMgcnDcViBcVoiiRDvebIQA17';
export const CAPTCHA_SECRET = '6LfVwlkqAAAAAChxvEAszxNl-8e-RCi-JRO2JYth';
export const LIMIT_CHARACTERS = 'Character limit exceeded';
export const VALID_LINKEDIN_URL = 'Please enter a valid LinkedIn URL starting with www.linkedin.com/';

