import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   _id: null,
   secret: null
}

const credentialsInterviewSlice = createSlice({
   name: 'credentialsInterviewSlice',
   initialState,
   reducers: {
      setCredentialsInterview: (state, action) => {
         console.log("action.payload._id", action.payload._id);
         state._id = action.payload._id;
         state.secret = action.payload.secret;
      }
   }
});

export const { setCredentialsInterview } = credentialsInterviewSlice.actions;
export default credentialsInterviewSlice.reducer;
