import React from 'react';
import {Box, Tabs, Tab} from "@mui/material";
import PropTypes from 'prop-types';
import TestGenerator from "./TestGenerator";
import useTestGeneratorController from "../../../../view-controlllers/admin/useTestGeneratorController";
import InputSearch from "../../../components/ui/InputSearch";
import AppliedCandidates from "./AppliedCandidates";
import Pagination from "../../../components/ui/Pagination/Pagination";
import useAppliedCandidatesController from "../../../../view-controlllers/admin/useAppliedCandidatesController";

function CustomTabPanel(props) {
   const {children, value, index, skillId, isFetchingSkill, fetchPdf} = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
      >
         {value === index && (
            <Box>
               {React.isValidElement(children) ? React.cloneElement(children, {
                  skillId,
                  isFetchingSkill,
                  fetchPdf
               }) : children}
            </Box>
         )}
      </div>
   );
}

CustomTabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
   skillId: PropTypes.any,
   isFetchingSkill: PropTypes.bool,
   fetchPdf: PropTypes.func,
};

const CustomTabs = () => {
   const [value, setValue] = React.useState(0);

   const {skillId, isFetchingSkill, fetchPdf} = useTestGeneratorController();
   const {
      handleFilterChange,
      paginatedData,
      page,
      setPage,
      totalPages
   } = useAppliedCandidatesController();

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <Box sx={{width: '100%'}}>
         <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
               <Tab label="INTERVIEW"/>
               <Tab label="APPLIED CANDIDATES"/>
            </Tabs>
         </Box>
         <CustomTabPanel value={value} index={0} skillId={skillId} isFetchingSkill={isFetchingSkill}>
            <TestGenerator
               skillId={skillId}
               isFetchingSkill={isFetchingSkill}
            />
         </CustomTabPanel>
         <CustomTabPanel value={value} index={1}>
            <Box my={4}>
               <InputSearch
                  onChange={handleFilterChange}
                  title="Search"
                  styles={{
                     height: "30px",
                     backgroundColor: "white",
                  }}
               />
               <AppliedCandidates
                  skillId={skillId}
                  downloadPDF={fetchPdf}
                  data={paginatedData}
               />
               <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
               >
                  <Pagination
                     setPage={setPage}
                     page={page}
                     totalPages={totalPages}
                  />
               </Box>
            </Box>
         </CustomTabPanel>
      </Box>
   )
}
export default CustomTabs;
