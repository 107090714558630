import Alert from '@mui/material/Alert';
import useAppContext from "../../../hooks/useAppContext";
import {useEffect} from "react";

const CustomAlert = ({variant, duration, style}) => {

   const {isActiveAlert, messageAlert, severityAlert, onSetOpenAlert} = useAppContext();

   useEffect(() => {
      const timeOutDuration = duration || 1000;
      setTimeout(() => {
         onSetOpenAlert({
            isActiveAlert: false,
            errorAlert: false,
            messageAlert: '',
            severityAlert: null,
         })
      }, timeOutDuration)
   }, [isActiveAlert, duration]);

   if (!isActiveAlert) return null;

   return (
      <Alert variant={variant} severity={severityAlert} sx={style}>
         {messageAlert}
      </Alert>
   )
}
export default CustomAlert;
