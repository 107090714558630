import {createContext, useMemo, useState, useEffect, useRef} from "react";
import {useSpeech} from "react-text-to-speech";
import platform from "platform";
import {BROWSER} from "../../data/constant/Constants";

export const SpeechContext = createContext({
   statusSpeech: null,
   currentQuestion: null,
   currentSpeechStatus: null,
   previousSpeechStatus: null,
   onInitSpeech: null,
   isSpeechAssistant: false,
   isSpeech: false,
   isAnimationSpeech: false,
   isAnimationSpeechAI: false,
   startSpeech: () => null,
   pauseSpeech: () => null,
   stopSpeech: () => null,
   onSetCurrentQuestion: () => null,
   onSetInitSpeech: () => null,
   onSetStatusSpeech: () => null,
   setIsSpeechAssistantState: () => null,
   setIsSpeechState: () => null,
   onSetIsAnimationSpeech: () => null,
   onSetIsAnimationSpeechAI: () => null,
});

const SpeechContextProvider = ({children}) => {
   const [currentQuestion, setCurrentQuestion] = useState(null);
   const [currentSpeechStatus, setCurrentSpeechStatus] = useState(null);
   const [previousSpeechStatus, setPreviousSpeechStatus] = useState(null)
   const [onInitSpeech, setOnInitSpeech] = useState(false);
   const [isSpeechAssistant, setIsSpeechAssistant] = useState(false);
   const [isSpeech, setIsSpeech] = useState(false);
   const [statusSpeech, setStatusSpeech] = useState(null);
   const [isAnimationSpeech, setIsAnimationSpeech] = useState(false);
   const [isAnimationSpeechAI, setIsAnimationSpeechAI] = useState(false)

   const prevSpeechStatus = useRef();

   const voices = speechSynthesis.getVoices();
   const defaultVoice = voices.find(voice => voice.name === 'Samantha') || voices[0];

   const {start, stop, pause, speechStatus} = useSpeech({
      text: currentQuestion,
      lang: 'en-US',
      rate: platform?.name === BROWSER[2] ? 0.9 : 1,
      voiceURI: [
         defaultVoice,
         'urn:moz-tts:osx:com.apple.voice.compact.en-US.Samantha',
         'urn:moz-tts:sapi:Microsoft Zira - English (United States)?en-US'
      ],
      autoPlay: true,
      onError: (error) => console.log('Error', error)
   });

   useEffect(() => {
      if (prevSpeechStatus.current === 'started' && speechStatus === 'stopped') {
         setStatusSpeech('stopped');
         setIsAnimationSpeechAI(false)
         setIsAnimationSpeech(false);
      } else if (speechStatus === 'started') {
         setStatusSpeech('started');
         setIsSpeech(true);
      } else {
         setStatusSpeech(null);
      }
      prevSpeechStatus.current = speechStatus;
   }, [speechStatus, statusSpeech]);

   const onSetCurrentQuestion = (question) => {
      setCurrentQuestion(question)
   }

   const stopSpeech = () => stop();
   const startSpeech = () => start();
   const pauseSpeech = () => pause();

   const onSetInitSpeech = (state) => {
      setOnInitSpeech(state)
   }

   const onSetStatusSpeech = () => {
      setPreviousSpeechStatus(null);
      setCurrentSpeechStatus(null);
   }

   const setIsSpeechAssistantState = (state) => {
      setIsSpeechAssistant(state)
   }

   const setIsSpeechState = (state) => {
      setIsSpeech(state);
   }

   const onSetIsAnimationSpeech = (state) => {
      setIsAnimationSpeech(state)
   }

   const onSetIsAnimationSpeechAI = (state) => {
     setIsAnimationSpeechAI(state)
   }

   const contextSpeech = useMemo(() => ({
      currentQuestion,
      currentSpeechStatus,
      previousSpeechStatus,
      onInitSpeech,
      isSpeechAssistant,
      isSpeech,
      statusSpeech,
      isAnimationSpeech,
      isAnimationSpeechAI,
      onSetCurrentQuestion,
      onSetInitSpeech,
      onSetStatusSpeech,
      setIsSpeechAssistantState,
      setIsSpeechState,
      onSetIsAnimationSpeech,
      onSetIsAnimationSpeechAI,
      startSpeech,
      pauseSpeech,
      stopSpeech,
   }), [
      statusSpeech,
      currentQuestion,
      currentSpeechStatus,
      previousSpeechStatus,
      onInitSpeech,
      isSpeechAssistant,
      isSpeech,
      isAnimationSpeech,
   ])
   return <SpeechContext.Provider value={contextSpeech}>{children}</SpeechContext.Provider>
}

export default SpeechContextProvider;
